import { useEffect, useState } from "react";
import { imageKeyUrlMap } from "../../../types/library/libraryUI";

type SignedUrlImageProps = {
  imageKey: string,
  imageUrlCache: imageKeyUrlMap,
  setImageUrlCache: React.Dispatch<React.SetStateAction<imageKeyUrlMap>>
  onError: () => void
}

const SignedUrlImage: React.FC<SignedUrlImageProps> = ({
  imageKey,
  imageUrlCache,
  setImageUrlCache,
  onError
}) => {
  const [imageUrl, setImageUrl] = useState("");

  const fetchImageUrl = async (key: string, signal: AbortSignal) => {
    try {
      const url = 'https://petergregory.dev/.netlify/functions/fetchBookCover?key=' + encodeURIComponent(key);
      const response = await fetch(url, { signal });
      const data = await response.json();
      setImageUrlCache(cache => new Map(cache).set(key, data.url));
      setImageUrl(data.url);
    } catch (error) {
      if (error instanceof Error && error.name !== 'AbortError') {
        console.error("Error fetching signed URL:", error);
        onError();
      }
    }
    
  };
  
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
  
    if (imageUrlCache.has(imageKey)) {
      setImageUrl(imageUrlCache.get(imageKey) ?? '');
    } else {
      fetchImageUrl(imageKey, signal);
    }
  
    return () => controller.abort();
  }, [imageKey]);
  
  return (
    imageUrl ? < img src={imageUrl} alt="Library Book" /> :

      <p>Loading...</p>
  )
}

export default SignedUrlImage