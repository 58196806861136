import { createContext } from 'react';
import { Notification, WindowStates } from '../../types/types';
import { initialWindowStates } from '../../config/initialWindowStates';

type WindowContextType = {
  windows: WindowStates;
  setWindows: React.Dispatch<React.SetStateAction<WindowStates>>;
  dockHidden: boolean;
  setDockHidden: React.Dispatch<React.SetStateAction<boolean>>
  firstVisit: boolean;
  setFirstVisit: React.Dispatch<React.SetStateAction<boolean>>
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>
};

const defaultContextValue: WindowContextType = {
  windows: initialWindowStates,
  setWindows: () => { },
  dockHidden: false,
  setDockHidden: () => { },
  firstVisit: true,
  setFirstVisit: () => { },
  notifications: [],
  setNotifications: () => { }
};

const AppContext = createContext<WindowContextType>(defaultContextValue);

export default AppContext;
