import { Modal } from '../../layouts/Modal/Modal'
import { AuthenticationIcon } from '../../ui/icons/AuthenticationIcon/AuthenticationIcon'
import styles from './Authentication.module.sass'

type AuthenticationProps = {
  setAuthenticationIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const Authentication: React.FC<AuthenticationProps> = ({
  setAuthenticationIsOpen
}) => {
  return (


    <Modal onStateChange={setAuthenticationIsOpen}>

      <Modal.Toggle >
        <div className={styles.authenticationIcon}>
          <AuthenticationIcon />
        </div>
      </Modal.Toggle>

      <Modal.Box>
        <div className={`${styles.loginContainer} global_box`}>
          <h3>USER LOGIN</h3>
          <form>
            <div className={styles.username}>
              <label htmlFor="username">Username:</label>
              <input type="text" id="username" />
            </div>
            <div className={styles.password}>
              <label htmlFor="password">Password:</label>
              <input type="password" id="password" />
            </div>
            <div className={styles.submit}>
              <input type="submit" value="LOG IN"/>
            </div>
          </form>
        </div>
      </Modal.Box>

    </Modal>

  )

}