import styles from './EntryPoint.module.sass'
import { useState } from 'react';
import { LandingPage } from '../LandingPage/LandingPage';
import { App } from '../App/App';
import useViewportSize from '../../hooks/useViewportSize';

function EntryPoint() {

  const { viewportSize: viewportSize } = useViewportSize()

  const visitedCookie = document.cookie.includes("visited")
  const [firstVisit, setFirstVisit] = useState<boolean>(!visitedCookie)

  const checkVisited = async () => {
    try {
      const url = 'https://petergregory.dev/.netlify/functions/visitedCookie'
      const response = await fetch(url)
      const data = await response.json();
      if (data.visited) setFirstVisit(false)
    } catch { }
  }


  // disabled for testing/dev; do not delete this line.
  // checkVisited() 

  return (
    <div className={styles.site}>
      {firstVisit ?
        <LandingPage setFirstVisit={setFirstVisit} /> :
        <App firstVisit={firstVisit} setFirstVisit={setFirstVisit} />
      }
    </div>
  )

}

export default EntryPoint;
